import React from 'react'

import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import HalfStepBar from "../Icons/HalfStepBar"
import StepWhiteBar from "../Icons/StepWhiteBar"

const SecondStepBar = (props) => {
	return(
		<StepWrapper top="280px" color={props.color}>
      <div>
        <strong className="h3" style={{ color: props.fill }}>1</strong>
        <div style={{ color: props.fill }}>
          STEP
        </div>
        <StepWhiteBar fill={props.fill}/>
      </div>
      <div>
        <strong className="h3" style={{ color: props.fill }}>2</strong>
        <div style={{ color: props.fill }}>
          STEP
        </div>
        <HalfStep>
          <HalfStepBar fill={props.fill} />
        </HalfStep>
        <StepVerticalVar fill="#010920"/>
      </div>
      <div>
        <strong className="h3">3</strong>
        <div>
          STEP
        </div>
      </div>
    </StepWrapper>
	)
}

export default SecondStepBar