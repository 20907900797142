import React from 'react'

import { StepWrapper } from "../Section"
import StepWhiteBar from "../Icons/StepWhiteBar"

const ThirdStepBar = (props) => {
  return(
    <StepWrapper top="280px" color={props.color}>
      <div>
        <strong className="h3" style={{ color: props.fill }}>1</strong>
        <div style={{ color: props.fill }}>
          STEP
        </div>
        <StepWhiteBar fill={props.fill}/>
      </div>
      <div>
        <strong className="h3" style={{ color: props.fill }}>2</strong>
        <div style={{ color: props.fill }}>
          STEP
        </div>
        <StepWhiteBar fill={props.fill}/>
      </div>
      <div>
        <strong className="h3" style={{ color: props.fill }}>3</strong>
        <div style={{ color: props.fill }}>
          STEP
        </div>
      </div>
    </StepWrapper>
  )
}

export default ThirdStepBar